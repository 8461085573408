<template>
  <div class="box">
    <div class="dataplus" v-for="(item,index) in list" :key='index'>        
        <div class="datainfo">
            <p class="p1">流量加油包</p>
            <p class="p2"><span class="p2-1">{{item.con_unit}}</span><span class="p2-2">G</span></p>
            <p class="p3">有效期{{item.validity_day}}天</p>
        </div>
        <div class="price">￥{{item.price}}</div>
    </div>
    <p class="title">您好!关于流量包我们有话说:</p>
    <p class="content-text">由于供应商政策原因，加油包是要比基础流量包贵一些的，且仅限当期套餐期限内使用，当期套餐结束，加油清零我们也感觉到比较遗憾，您今后使用流量时可以随时查看流量使用情况不要超哦~</p>

    <!-- <div class="recommend">
        <div class="one">
            <p class="one-title">超值推荐</p>
            <p class="one-content">多张卡轮换使用更超值更省心</p>
        </div>
        <div>
            <button>去购卡</button>
        </div>
    </div>
    -->
    <div class="pay">
        <button class="payobutton" @click="queryPay()">立即支付(￥25)</button>
    </div>
  <!--  -->
   <van-popup
      v-model="reshow"
      position="bottom"
      :style="popupHeight"
      round
      closeable
    >
      <div class="popup-top">
        <div class="pop-center">
          <div class="popup-title">
            ICCID:<span>{{ iccid }}</span>
          </div>
          <div class="popup-toast">
            <span class="money">{{ info.c_balance }}</span><span
              class="company">元</span>当前余额不足,请及时充值！
          </div>
        </div>
        <div class="center-box">
          <p class="center-text">充值金额</p>
          <div class="moneybox">
            <mealbox
              v-for="item in meallistbox"
              :key="item.id"
              :item="item"    
              :active="actives"
              @selectitem="selectitem($event)"
            ></mealbox>
          </div>
          <div class="inmeal">
            {{ info.pay_status == 0 ? "未订购套餐" : info.cur_buy_name }}</div>
        </div>
             <div>
        <paymode
        @change="paychange($event)"
        v-if="!weixin"
      ></paymode>
        </div>

        <querytoastbox
      :show="queryShow"
      @clkleft="queryShow = false"
      @clkright="clkQuqeryRight"
    >
      <template #center>
        <div class="querycenter">即将打开"锐wifi"小程序</div>
      </template>
    </querytoastbox>

      </div>
   
      <bluebutton
        :title="titletext"
        @clkbutton="clkbutton"
        :isloading="isloading"
      ></bluebutton>
    </van-popup>
    <querytoastbox
      :show="queryShow2"
      @clkleft="queryShow2 = false"
      @clkright="clkQuqeryRight2"
    >
      <template #center>
        <div class="querycenter2">您确定要使用余额购买加油包吗？如果订购套餐流量未到账，您需要稍等几分钟之后再试。</div>
      </template>
    </querytoastbox>
          <!-- @clknew="clknew" -->
   <querytoastbox
      :show="newbuttomshow"
      :isnewButtm="true"
    >
      <template #center>
        <div class="newquerycenter">多次打开微信支付可能会被限制，如果无法正常唤起请选择支付宝支付</div>
      </template>
    </querytoastbox>
  </div>
</template>

<script>
import querytoastbox from "@/components/querytoastbox/index.vue"
import mealbox from "@/components/mealbox/index.vue"
import bluebutton from "@/components/bluebutton/index.vue"
import isweixin from "@/untils/isweixin.js"
import isAli from "@/untils/isAli"
import isPhone from "@/untils/isPhone.js"
import paymode from "@/components/paymode/index.vue"
import paymixin from "@/mixin/paymixin.js"
export default {
  name: 'dataPluss',
  components:{
        paymode,
        bluebutton,
        mealbox,
        querytoastbox
  },
  data(){
     return {
        newbuttomshow:false,//多次打开可能会被封的提示
        timer:null,
        queryShow2:false,  //弹框确认
        isDataPlus:true,  //是否是流量包
        list:[],   //列表
        active:{},  //选中的项
        iccid:'',   //
        info:{},   //详情
        titletext: "立即充值",
        actives: 1,  // 默认选中的值
        payradio:1,   //选中微信或者是支付宝
        activeItme: {
        money: 80,
        id: 1,
      },// 默认选中的充值钱数
        isloading:true,
         queryShow: false,  // 是否显示确认前往微信小程序
        popupHeight: {},  // 根据手机系统判断弹出高度
        weixin: false,  // 是否在微信环境下
        reshow:false,//弹窗显示隐藏
        rightNum: 0,  // 点击允许跳转小程序按钮次数
        meallistbox: [
        // {
        //   money: 80,
        //   id: 1,
        // },
        // {
        //   money: 100,
        //   id: 2,
        // },
        // {
        //   money: 120,
        //   id: 3,
        // },
        // {
        //   money: 150,
        //   id: 4,
        // },
        // {
        //   money: 180,
        //   id: 5,
        // },
        // {
        //   money: 200,
        //   id: 6,
        // },
      ], // 充值金额数组
        }

        },
     mixins:[paymixin],
     mounted(){
       this.Rechargelist()
       this.weixin = isweixin()
       this.setPHeight()
       this.getList()

       if (isAli()) {
            this.payradio = "2"
          }
        },
  methods:{
      //是否确认支付
    queryPay(){
      this.queryShow2 = true
    },

    clkQuqeryRight2(){
      //点击确认购买加油包
      this.queryShow2 = false
      if(this.timer){
          return 
      }
      this.timer = setTimeout(()=>{
         this.timer = null
          this.btnPay()
      },1000)


   


    },
    clkQuqeryRight () {
      // 点击了确定前往微信小程序
      this.rightNum++
      if (this.rightNum < 2) {
        setTimeout(() => {
          // this.alipayshow = true
        }, 1000)
      }
      this.jumpApplet()
    },
    // 跳往微信小程序
    jumpApplet () {
      if ((!this.weixin) && this.payradio == 1) {
        if (this.rightNum < 2) {
          let iccid = window.sessionStorage.getItem("iccid")
          let data = {
            iccid: iccid,
            type: 2
          }
          this.getUrl(data)
        }
        if (this.rightNum >= 2) {
          // this.alipayshow = false
          this.queryShow = false
          // this.newbuttomshow = true
        }
      }
      return false
    },
      // 支付方式发生了改变
    paychange ($event) {
      this.payradio = $event
    },
    // 立即充值按钮
    clkbutton () {
      if ((!this.weixin) && this.payradio == 1) {
        this.queryShow = true
        return false
      }
  
      let data = {
        price: this.activeItme.money, // 金额
        iccid: this.iccid,  // 套餐设备id
        phone: sessionStorage.getItem("phone"),  //手机号
        type: 3,  //购买类型 3充值余额  4 购买加油包
        buy_id: -1,  // 购买id
        platform: 1, // 1是h5
        openid: sessionStorage.getItem("openid"),  // 微信openid
        pay_type: this.payradio == 1 ? "1" : "5",  // 支付状态1是微信5是支付宝
        
      }
      // if(this.timer){
      //   return 
      // }
      // this.timer = setTimeout(()=>{
      //    this.timer = null
      //     // this.titletext = "立即充值"
      // },1000)
      this.isloading = false
      // this.titletext = "充值中..."
      this.setpayinfo(data)
      
     
    // console.log(data);
    },

       // 选中的actice
    selectitem ($event) {
      this.actives = $event.id
      this.activeItme = $event
    },
    // 根据机型判断高度
    setPHeight () {
      if (isPhone() && document.documentElement.clientHeight <= 667) {
        this.popupHeight = {
          height: "70%"
        }
      } else {
        this.popupHeight = {
          height: "60%"
        }
      }
    },
     paychange ($event) {
      this.payradio = $event
    },
    //获取套餐列表
    getList(){
        this.iccid = window.sessionStorage.getItem("iccid")
        let data = {
            iccid:sessionStorage.getItem("iccid"),
            type:2,
            openid:sessionStorage.getItem('openid')
        }
        if(!this.weixin){
          delete(data.openid)
        }
        this.getDataPlus({ data }).then(res=>{
            this.list = res.data.list
            this.list[0].price = Math.trunc(res.data.list[0].price)
            this.list[0].con_unit = this.list[0].con_unit.replace('G', '')
            // console.log(this.list );
            this.active = this.list[0]
            // console.log(this.active);
        })
    },
    //充值列表
    Rechargelist(){
        let data ={
        iccid:sessionStorage.getItem("iccid")
      }
      this.getRechargeList({data}).then(res =>{
        // console.log(res);
        this.meallistbox = res.data.list
        console.log(this.meallistbox);
        this.info = res.data.info
        this.activeItme = this.meallistbox[0]
        this.actives = this.meallistbox[0].id
      })
    },

    //点击支付
    btnPay(){

        let iccid = window.sessionStorage.getItem("iccid")
        let data = {
        type: 4,  //购买类型
        buy_id: this.active.id,  //套餐id
        phone: sessionStorage.getItem("phone"),  //手机号
        price: this.active.price, // 金额
        iccid: iccid,  // 套餐设备id
      }
        let Paydata = {
        price:this.active.price, // 金额
        iccid:iccid,  // 套餐设备id
        phone:sessionStorage.getItem("phone"),  //手机号
        type:4,  //购买类型 4 加油包
        buy_id:this.active.id,  // 购买id
        platform:1, // 1是h5
        openid:sessionStorage.getItem("openid"),  // 微信openid
        pay_type: 2, //1是微信 2是余额
        has_free_package: 0
      }

        this.checkBalance({ data }).then(res=>{
            if (this.active.is_default == 1 && res.data.code == 0) {
                    this.prohibit = false
                    this.$toast("余额不足,请充值")
                    // 为了适应小流量套餐 套餐充值金额首个可选项不固定
                    // if (this.active.price <= 50) {
                    //     this.meallistbox.shift()
                    //     this.meallistbox.unshift({
                    //     money: 50,
                    //     id: 1
                    //     })
                    //     if (this.activeItme.id == 1) {
                    //     // 如果用户没有进行充值金额改变，则需要改变初始默认值
                    //     // 改变初始选中的钱数
                    //     this.activeItme.money = 50
                    //     }
                    // } else {
                    //     this.meallistbox.shift()
                    //     this.meallistbox.unshift({
                    //     money: 80,
                    //     id: 1
                    //     })
                    //     if (this.activeItme.id == 1) {
                    //     // 如果用户没有进行充值金额改变，则需要改变初始默认值
                    //     // 改变初始选中的钱数
                    //         this.activeItme.money = 80
                    //     }
                    // }
                    this.reshow = true
                    return false
                    } else if (res.data.code == 0 && this.active.is_default == 0) {
                    this.$toast("余额不足,你还需要支付" + res.data.price + "元")
                    this.setpayinfo(Paydata)
                    } else if (res.data.code == 1) {
                    this.$toast("余额充足,你将使用余额支付")
                    this.setpayinfo(Paydata)
                    }

        })

    //   
    // console.log(data);

    }
    

  }
  };
</script>



<style scoped lang="less">
.box {
    min-height: 100vh;
    padding: 15px;
    box-sizing: border-box;
    // background-color: aquamarine;
    .dataplus {
        position: relative;
        width: 100%;
        height: 120px;
        // background-color: rgb(192, 86, 45);
        background: url('~@/assets/images/dataPlus/dataPlusBg.png') no-repeat;
        background-size: contain;
        .datainfo {
            position: absolute;
            top: 15px;
            left: 50%;
            transform: translateX(-50%);
            width: 90px;
            height: 90px;
            // background-color: #666;
            .p1{
                font-size: 16px;
                color: #e46509;
            }
            .p2 {
                margin: 5px 0;
            }
            .p2-1{

                font-size: 50px;
                color: white;
            }
            .p2-2{
                font-size: 25px;
                color: white;
            }
            .p3 {
                font-size: 12px;
                color: #bb8a3d;
            }
        }
        .price {
            position: absolute;
            right: 20px;
            bottom: 20px;
            font-size: 30px;
            font-weight: 700;
        }
    }
    .title{
        margin-top: 25px;
        margin-bottom: 15px;
        font-weight:600 ;
        font-size: 16px;
    }
    .content-text {
        font-size: 14px;
        color: #666;
        margin-bottom: 5px;
        line-height: 22px;
    }
    .recommend {
        padding: 0 10px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        width: 100%;
        // background:  #353638;
               background: url('~@/assets/images/dataPlus/buyCard.png') no-repeat;
            background-size: contain;
        margin-top: 25px;
        .one{
            height: 50px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            color: white;
     
            .one-title {
                font-size: 16px;
            }
            .one-content {
                font-size: 10px ;
            }
        }
        button{
            width: 100px;
            height: 30px;
            background-color: #e24f0b;
            color: white;
            border-radius: 15px;
        }
        button:active{
             background-color: #b6410b;
        }
    }

    .pay{
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        .payobutton {
            color: white;
            width: 200px;
            height: 40px;
             background-color: #e72344;
            border-radius: 20px;
        }
        .payobutton:active {
            background-color: #f56079;
        }
    }



     .popup-top {
    width: 100%;
    background-color: #f6f8fc;
    height: 86px;

    .pop-center {
      width: 92%;
      margin: 0 auto;
      height: 86px;

      .popup-title {
        padding-top: 15px;
        color: #333333;
        font-size: 14px;
        font-weight: 600;

        .span {
          font-family: "bahn";
        }
      }

      .popup-toast {
        font-size: 12px;
        color: #b6c2d6;
        margin-top: 14px;

        .money {
          font-size: 26px;
          color: #f11a52;
          font-family: "bahn";
        }

        .company {
          color: #f11a52;
          margin-right: 12px;
        }
      }
    }

    .center-box {
      width: 92%;
      margin: 0 auto;

      .center-text {
        font-size: 14px;
        font-weight: 600;
        color: #222;
        margin: 20px 0;
      }

      .moneybox {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
      }

      .inmeal {
        color: #dce5f5;
        margin-bottom: 16px;
      }
    }
  }

    .querycenter {
    text-align: center;
    height: 80px;
    line-height: 80px;
    font-size: 14px;
    font-weight: 600;
  }

   .querycenter2 {
    // text-indent: 2em;
    text-align: left;
    height: 80px;
    line-height: 25px;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 10px;
  }

}


</style>